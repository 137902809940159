// Override default variables before the import
$body-bg: #fff;
$primary: #fc9200;
$bg-primary: #fc9200;
$bg-secondary: #656565;
$bg-dark: #000;

$theme-colors: (
  "info": tomato,
  "danger": rgb(207, 25, 25),
  "primary": #fc9200,
  "dark": #656565,
  "secondary": #656565,
  "warning": #e1a714,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import "./components/Navbar/navbar.scss";
@import "./components/Sidebar/sidebar.scss";
@import "./components/Dashboard/Reports/reports.scss";
@import "./components/Projects/AddProjects/addproject.scss";
@import "./components/Projects/AddProjects/projectList.scss";
@import "./components/Projects/CreateTranche/createtranche.scss";
@import "./components/Projects/CreateTranche/trancheList.scss";
@import "./components/Investments/Investments/investments.scss";
@import "./components/Investments//Investors/payinvestor.scss";

body {
  font-family: "Segoe UI" !important;
}

body:not(*:root) {
  font-family: "Segoe UI" !important;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Light");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Semilight");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI Semibold");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff")
      format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff")
      format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff")
      format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2")
      format("woff2"),
    url("https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff")
      format("woff");
  font-weight: 600;
  font-style: normal;
}

//bootstrap tabs custom

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: #fc9200;
  border-color: #fc9200;
  border-radius: 0px;
  font-weight: 800;
  width: 100%;
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  background: darkgray;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 250px;
  color: black;
  font-weight: 800;
  height: 75px;
}
.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: #fff;
  margin-left: -28px;
  margin-top: -3px;
}

//OVERFLOW

::-webkit-scrollbar {
  height: 6.5px;
  width: 6.5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darkgray;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.button {
  width: 150px;
  margin-left: 15px;
  height: 50px;
  color: white;
  font-weight: 700;
}
.listButton {
  margin-right: auto;
  margin-left: 0px;
}

.paymentButton {
  width: 150px;
  border: 1px solid #faa619;
  margin-left: 10px;
  color: #fff;
}

.MuiTableSortLabel-icon {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 18px;
  margin-right: 4px;
  margin-left: 4px;
  opacity: 1;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
  opacity: 1 !important;
  color: white !important;
}

.css-f3gus1-MuiButtonBase-root-MuiTableSortLabel-root.Mui-active
  .MuiTableSortLabel-icon {
  opacity: 1 !important;
  color: white !important;
}
.orange_heading {
  font-weight: bold;
  color: #faa619;
  font-size: 18px;

}