.headingStyle {
  margin-top: -20px;
  margin-left: -20px;
  margin-bottom: 20px;
  border-color: orange;
  width: 300px;
  height: 40px;
  padding: 7px;
}

.details_container_fluid{
  padding: 2rem;
}
.details_container{
  background-color: #FFF;
  border: 2px solid #faa51b;
  // border: 1px solid #111;
}
.heading_parent{
  position: relative;
}
.heading_child{
    position: absolute;
    top: -48px;
    left: -20px;
}
.heading_font{
  display: inline-block;
  padding: 5px 10px;
  padding-right: 5rem;
  background-color: #faa51b;
  font-size: 18px;
  color: #111;
  font-weight: 700;
}
.details_heading_row{
  border-top: 1px solid #adb5bd;
  padding: 1rem 0;
}
.details_text_row{
  display: flex;
}
.details_text_column{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem ;
}
.details_text_column p{
  margin-bottom: 5px;
}
.details_para_head{
  font-size: 16px;
  font-weight: 600;
  color:  #343a40;;
  text-transform: uppercase;
}
.details_para{
  font-size: 16px;
  color: #495057;
  text-transform: capitalize;
}
.details_text_column_para_head{
  font-size: 14px;
  color:#868e96;
  text-transform: capitalize;
  margin-bottom: 0px;
}
.details_text_column_para{
  font-size: 16px;
  color: #343a40;
  padding-left: 0px;
  margin-bottom: 5px;
}
.details_form_input{
  font-size: 18px;
  font-weight: 600;
  color: #343a40;
}
.form_radio_flex{
  display: flex;
  justify-content: space-around;
}
.details_form_formBox{
  border: 2px solid #ccc;
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 5px;
  text-align: center;
}
.details_form_formBox:focus{
  border: none;
  outline: 2px solid #faa51b;
}
.details_form_field_padding{
  padding: 1rem 0;
}

input[type="date"]::after{
  content: "";
  display: block;
  background-color: red;
}
/* for date  */

input[type="date"]::-webkit-calendar-picker-indicator {
  // color: rgba(0, 0, 0, 0);
  // opacity: 1;
  // display: block;
  background: url("../../../assets/images/calendar.png") no-repeat;  background-size: 100% 100%;
  content: " ";
  width: 24px;
  padding-right: 0;
  display: inline-block;

  // width: 20px;
  // height: 20px;
  // border-width: thin;
}
/* for date  */


/* for file choose  */
input::file-selector-button {
  display: none;
}

.infra_form input[type=file]::after{
  display: inline-block;
  margin-left: 50%;
  background-image: url("../../../assets/images/file.png");
  background-size: 100% 100%;
  content: " ";
  width: 24px;
  padding-right: 0;
}


/* for radio file choose  */


/* for radio buttons  */

input[type=radio],
input[type=checkbox]{
  /* hide original inputs */
  visibility: hidden;
  position: absolute;
}
input[type=radio] + label{
  cursor:pointer;
}
input[type=radio] + label:before{
    margin-right: 10px;
    content: " ";
    display: inline-block;
    vertical-align: middle;
    transition: 0.3s;
    border: 1px solid rgb(250, 165, 27);
    border-radius: 10px;
    transition: 0.3s;
}
/* DEFAULT */
input[type=radio] + label:before{

  border-radius: 50%;
    width: 18px;
    height: 18px;
}
/* CHECKED */
input[type=radio]:checked + label:before{
  box-shadow: inset 0px 0px 0px 2px #fff, inset 0px 0px 0px 8px orange;
}

/* for -radio buttons */
.details_backBox{
  display: flex;
}
.details_button{
  padding: 0.5rem 2.9rem;
  border: 1px solid #111;
  font-weight: 600;
}
.details_button_full_width{
  padding: 0.5rem 0;
  width: 100%;
  border: 1px solid #111;
  font-weight: 600;
}
.details_form_label_span{
  color: red;
  position: absolute;
  top: -10px;
  right: -14px;
}
.details_form_label{
  margin-bottom: 10px;
}
.approval_box_outer{
  border: 1px solid #ccc;
    padding: 3rem 1rem;
    display: flex;
    justify-content: space-around;
}
.paymentLink_button{
  padding: 0.5rem 3rem;
  border: 1px solid rgb(250, 165, 27);
  background-color: rgb(250, 165, 27);
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.3px;
  font-size: 18px;
}
.upload_document_submit{
  padding: 6px 2.5rem;
  border: 1px solid #111;
  font-weight: 600;
  background-color: rgb(250, 165, 27);
  color: rgb(255, 255, 255);
  border-color: inherit;
}
.approval_button{
  width: 100%;
  padding: 0.5rem 0;
  border: 1px solid #111;
    font-weight: 600;
}
.heading_child_invest{
  position: absolute;
    top: -48px;
    right: 16px;
}

.details_form_formBox_outline{
  border: rgb(255, 255, 255);
  appearance: none;  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 5px;
  text-align: center;
  font-size: 14px;
}
.details_form_formBox_outline:focus{
  border: none;
  outline: 2px solid #faa51b;
}
.details_button_file{
  outline: none;
    border: none;
    background: none;
    color: orange;
    padding: 0;
    text-align: start;
}
.details_button_file:hover{
  color:#ca7500
}

// .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
//   position: relative;
// }


