.sidebar_container {
  background-color: #faa619;
  height: 100vh;
  width: 285px !important;
  position: sticky;
  top: 90px !important;
}
// .sidebar_container {
//   background-color: #fc9200;
//   max-width: 350px ;
//   min-height: 85vh;
// }
.sidebar_container_sm {
  background-color: #faa619;
  min-height: 100vh;
  width: 61px !important;
  position: sticky;
  top: 90px !important;
}
.toggle_button {
  background-color: $bg-dark;
  height: 90px !important;
  width: 66px !important;
}
.side_hover :hover {
  background-color: #000;
  transition: 300ms;
}
