.MuiTableRow-root {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
  // background-color: #4d4d4d;
  box-shadow: 4px 0 8px rgb(0 0 0 / 10%);
  display: table-row;
  top: 0;
}
