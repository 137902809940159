.loginform_container {
  // height: 300px;
  /* width: 300px; */
  margin-top: 100px;
  padding: 40px;

  background: #f9f9f9;
  border: 1px solid transparent;
}
.login_wrapeer {
  display: flex;
  align-items: center;
  justify-content: center;
}
