.tranche_details_fluid{
    padding: 2rem;
}
.tranche_details_container {
    background-color: #FFF;
    border: 2px solid #faa51b;
}
.tranche_details_container p{
    padding-left: 5px;

}
.tranche_details_column{
    margin: 1rem 0;
}
.tranche_details_heading{
    font-weight: 600;
    background-color: #faa619;
    color: #fff;
    font-size: 18px;
    margin: 0;
}
.tranche_details_para_head {
    font-size: 16px;
    color: #868e96;
    text-transform: capitalize;
}
.tranche_details_para {
    font-size: 14px;
    color: #343a40;
    text-transform: capitalize;
    letter-spacing: 0.2px;

}
.tranche_details_button{
    border-radius: 0px;
    background-color: orange;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 0.5rem 3rem;
    text-align: center;
    text-transform: capitalize;
}
.tranche_details_button:hover{
    color: #fff;
}
select{
    // width: 200px;
    width: 100%;
    border: 1px solid orange;
    padding: 4px 0;
    padding-left: 5px;
    letter-spacing: 0.2px;
}
select:focus{
    outline: 1px solid orange;
}

.agents_input{
    padding: 5px;
    width: 15rem;
    border: 1px solid orange;
    letter-spacing: 0.2px;
    margin-bottom: 0.5rem;
}
.agents_input:focus{
    outline-color: orange;
}
.agents_button{
    background-color: orange;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    // padding: 0.5rem 3rem;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
}
.tranche_input{
    width: 100%;
    border: 1px solid orange;
    padding: 4px 0;
    letter-spacing: 0.2px;
    padding-left: 5px;
}
.tranche_input:focus{
    outline: 1px solid orange;
}
