// *{
//   background-color: aqua;
// }
.App {
  background-image: url("./assets//images/pageBG.png");
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}


.lds-ring {
  // display: inline-block;
  // position: relative;
  // width: 80px;
  // height: 80px;
  // display: block;
  // width: 100%;
  // height: 100%;
  // position: absolute;
  // top:50%;
  // left: 50%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #adb5bd;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #adb5bd transparent transparent transparent;
}
//#fc9200
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 16px solid transparent;
  border-radius: 50%;
  border-top: 16px solid #f3f3f3;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin:auto;
  left:285px;
  right:0;
  top:0;
  bottom:0;
  position:fixed;
}
.loader1 {
  border: 16px solid transparent;
  border-radius: 50%;
  border-top: 16px solid #faa619;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin:auto;
  left:0;
  right:0;
  top:0;
  bottom:0;
  position:fixed;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.password_button {
  position: absolute;
  top: 2px;
  right: -16px;
  right: 1px;
  border: none;
  background: transparent;
}

.investTab {
    width: 102%;
    margin-left: 4px;
  }

@media only screen and (max-width: 800px) {
  .txtIndent {
    margin-top: 20px !important;
  }

  // .investTab {
  //   padding: 5px 10px;
  // }
}

.formCheckLabel label {
  display: inline-block;
  font-size: 10px;
}
.formCheckLabel .form-check-input:checked {
  background-color: #fc9200;
  border-color: #fc9200;
}

@media only screen and (max-width: 500px) {
  .formCheckLabel label {
    font-size: 12px !important;
  }
}
.h5,
h5 {
  font-size: 1.11rem;
}
@media (min-width: 1024px) {
  .formCheckLabel label {
    display: inline-block;
    font-size: 14px;
  }
}

.mncheckbox 
input[type=radio], input[type=checkbox] {
visibility: visible !important ; 
 position: relative !important; 
}
.orange_para {
  font-weight: bold;
}